import "./App.css";
import { FileUploader } from "react-drag-drop-files";
import { useState, useRef, useEffect } from "react";
import ToolBar from "./toolbar";
const fileTypes = ["JPG", "PNG", "TIFF", "JPEG", "GIF", "TIF"];

function App() {
  const [files, setFiles] = useState([]);
  const [fieldName, setFieldName] = useState("");
  const [flightDate, setFlightDate] = useState(
    new Date().toISOString().split("T")[0],
  );
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState([]);
  const [uploading, setUploading] = useState(false);
  const progressRef = useRef();
  const [hasHash, setHasHash] = useState(false);
  const [wakeLock, setWakeLock] = useState(null);
  const handleChange = (file) => {
    setFiles(file);
  };
  useEffect(() => {
    if ("wakeLock" in navigator && !wakeLock) {
      navigator.wakeLock.request("screen").then((wakeLock) => {
        console.log("Wake Lock is active");
        setWakeLock(true);
      });
    }

    //get hash from url
    const hash = window.location.hash;
    if (hash) {
      const hashArray = hash.split("#");
      const hashValue = hashArray[1];
      if (hashValue.length > 0) {
        setHasHash(true);
        setFieldName(hashValue);
      }
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (!fieldName) {
      setError("Please enter a field name");
      return;
    }
    if (files.length === 0) {
      setError("Please select a file to upload");
      return;
    }

    uploadFiles();
  };
  async function uploadFiles() {
    const batchSize = 200;
    const filesArray = Array.from(files);
    setUploading(true);
    if (!wakeLock) {
      setInterval(() => {
        window.focus();
        console.log("Keeping PC awake...");
      }, 30000);
    }
    for (
      let batchStart = 0;
      batchStart < filesArray.length;
      batchStart += batchSize
    ) {
      const batchFiles = filesArray.slice(batchStart, batchStart + batchSize);

      const uploadPromises = batchFiles.map(async (file, index) => {
        let url = "";
        if (process.env.NODE_ENV === "development") {
          url = `http://localhost:5000/`;
        } else {
          url = `/`;
        }
        setProgress((prev) => {
          prev[batchStart + index] = {
            file_id: file.name,
            progress: 0,
          };
          return [...prev];
        });
        const response = await fetch(
          `${url}api/generate-presigned-url?file_name=${file.name}&file_type=${file.type}&field_name=${fieldName.replace(/ /g, "_").replace(/[^a-zA-Z0-9-_]/g, "")}&flight_date=${flightDate.replace(/-/g, "_")}`,
        );
        const data = await response.json();

        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", data.url, true);
          xhr.setRequestHeader("Content-Type", "application/octet-stream");
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percent = (event.loaded / event.total) * 100;
              const progressIndex = batchStart + index;
              setProgress((prev) => {
                prev[progressIndex] = {
                  file_id: file.name,
                  progress: percent,
                };
                return [...prev];
              });
            }
          };

          xhr.onload = () => {
            if (xhr.status === 200) {
              const progressIndex = batchStart + index;

              setProgress((prev) => {
                prev[progressIndex] = {
                  file_id: file.name,
                  progress: 100,
                };
                if (
                  prev.filter((i) => i && i.progress === 100).length ===
                  filesArray.length
                ) {
                  setError(fieldName + ": Upload complete");
                  setUploading(false);
                  setFiles([]);
                  setFieldName("");

                  fetch(`${url}api/git_hub_ticket`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      body: "UAV flight uploaded for field " + fieldName,
                      label: "UAV",
                    }),
                  });
                  return [];
                }
                return [...prev];
              });
              resolve();
            } else {
              setError("Upload failed");
              reject(new Error("Upload failed"));
            }
          };
          xhr.onerror = () => {
            setError("Upload failed");
            reject(new Error("Upload failed"));
          };

          xhr.send(file);
        });
      });

      try {
        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Batch upload failed:", error);
      }
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <ToolBar />
        UAV Flight Data Uploader
        <form onSubmit={handleSubmit} className="form">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            {hasHash ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <label>Field Name: {fieldName}</label>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <label>Field Name:</label>
                <input
                  style={{ fontSize: "18px", width: "100%" }}
                  name="field name"
                  type="text"
                  disabled={uploading || hasHash}
                  value={fieldName}
                  onChange={(e) => setFieldName(e.target.value)}
                  // placeholder="Field Name"
                />
                <span style={{ fontSize: "12px" }}>
                  Note: Please do not include dates or use special characters.
                </span>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
                justifyContent: "flex-start",
                alignItems: "start",
              }}
            >
              <label>Flight Date:</label>
              <input
                style={{ fontSize: "18px", width: "100%" }}
                name="flight date"
                type="date"
                disabled={uploading}
                max={new Date().toISOString().split("T")[0]}
                value={flightDate}
                onChange={(e) => setFlightDate(e.target.value)}
                placeholder="Flight Date"
              />
            </div>
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            multiple
            disabled={uploading}
            types={fileTypes}
          >
            {files.length === 0 ? (
              <div className="upload">
                Drag & Drop UAV flight files here or click to select files
              </div>
            ) : files.length === 1 ? (
              <div className="upload">{files[0].name}</div>
            ) : (
              <div className="upload">{files.length} files selected</div>
            )}
          </FileUploader>
          <br />
          <div id="progressContainer" ref={progressRef}>
            {files.length > 0 && (
              <div>
                Upload Progress:
                {progress.filter((i) => i && i.progress === 100).length}/
                {files.length}
              </div>
            )}
            {uploading && (
              <div>Please do not close the window until upload is complete</div>
            )}
            {progress
              .filter((p) => p && p.progress !== 100)
              .map((p, index) => (
                <div
                  className="progress-bar"
                  style={{
                    backgroundColor: p.progress === 100 ? "green" : "yellow",
                    color: p.progress === 100 ? "white" : "black",
                  }}
                  key={index}
                >
                  {p.file_id}: {p.progress.toFixed(2)}%
                </div>
              ))}
          </div>
          <div className="error">{error ? error : ""}</div>
          {!uploading && (
            <button type="submit" className="button">
              Upload
            </button>
          )}
        </form>
      </header>
    </div>
  );
}

export default App;
