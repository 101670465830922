import logo from "./assets/logo.png";
const ToolBar = () => {
	return (
		<a href="/" aria-current="page" aria-label="home" className="logo">
			<div>
				<img
					src={logo}
					loading="eager"
					alt=""
					className="navbar-img-e"
				/>
			</div>
		</a>
	);
};
export default ToolBar;
