import { useState } from "react";
import ToolBar from "./toolbar";
import "./App.css";
const LogIn = () => {
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const handleSubmit = async (event) => {
		event.preventDefault();
		setError("");
		if (!password) {
			setError("Password is required!");
			return;
		}
		let url = "";
		if (process.env.NODE_ENV === "development") {
			url = "http://localhost:5000/login";
		} else {
			url = "/login";
		}

		const res = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ password }),
		});
		const text = await res.json();
		setError(text.error || "");
		console.log(text);
		if (text.success) {
			window.location.href = "/";
		}
	};
	return (
		<div className="App">
			<header className="App-header">
				<ToolBar />
				<form className="form" onSubmit={handleSubmit}>
					<input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						name="password"
						required
					/>
					<br />
					<div className="error">{error}</div>
					<button
						onClick={handleSubmit}
						type="submit"
						className="button"
					>
						Login
					</button>
				</form>
			</header>
		</div>
	);
};
export default LogIn;
